import logo from './logo.svg';
import './App.css';
import Header from './components/header';
import HeroComponent from './components/hero';
import Pricing from './components/pricing';

function App() {
  return (
    <div className="App">
      <Header />
      <HeroComponent />
      {/* <Pricing /> */}
    </div>
  );
}

export default App;
