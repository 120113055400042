import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  usersCard: {
    borderRadius: '10px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    // padding: theme.spacing(1),
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
      flexGrow: 0,
    },
  },
  usersTitle: {
    fontSize: '0.8rem',
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  usersCount: {
    marginTop: theme.spacing(1),
    fontSize: '2rem',
    fontWeight: 'bold',
    color: theme.palette.text.primary,
  },
}));

const DynamicUsers = () => {
  const classes = useStyles();
  const [users, setUsers] = useState(0);

  const fetchUsers = async () => {
    // Replace the URL with the actual endpoint
    const response = await fetch('https://api.example.com/users_count');
    const data = await response.json();
    setUsers(data.users);
  };

  useEffect(() => {
    fetchUsers();
    const interval = setInterval(fetchUsers, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Grid container justify="left">
      <Grid item xs={12} sm={8} md={4}>
        <Card className={classes.usersCard}>
          <CardContent>
            <Typography variant="subtitle1" className={classes.usersTitle}>
              Today's Users
            </Typography>
            <Typography variant="h2" className={classes.usersCount}>
              {users}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default DynamicUsers;
