import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography } from '@material-ui/core';
import GetAccessForm from './formcomponent';
import SignUp from './signup';

import '../css/hero.css';
import { FREE_TRIAL_AMOUNT } from '../consts';
import DynamicUsers from './dynamic';

const useStyles = makeStyles((theme) => ({
  hero: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    minHeight: '100vh',
    padding: theme.spacing(8, 0),
	textAlign: 'left',
  },
  heroTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  heroImageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'top',
  },
  heroImage: {
    maxHeight: '75%',
    maxWidth: '100%',
  },
}));


// const AppScreenshot = () => {
// 	return (
// 		<div className="mt-8 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
// 			<svg
// 				viewBox="0 0 366 729"
// 				role="img"
// 				className="mx-auto hero-image"
// 				>
// 				<title>App screenshot</title>
// 				<defs>
// 					<clipPath id="2ade4387-9c63-4fc4-b754-10e687a0d332">
// 						<rect width="316" height="684" rx="36"></rect>
// 					</clipPath>
// 				</defs>
// 				<path
// 					className="hero-image-bg"
// 					d="M363.315 64.213C363.315 22.99 341.312 1 300.092 1H66.751C25.53 1 3.528 22.99 3.528 64.213v44.68l-.857.143A2 2 0 0 0 1 111.009v24.611a2 2 0 0 0 1.671 1.973l.95.158a2.26 2.26 0 0 1-.093.236v26.173c.212.1.398.296.541.643l-1.398.233A2 2 0 0 0 1 167.009v47.611a2 2 0 0 0 1.671 1.973l1.368.228c-.139.319-.314.533-.511.653v16.637c.221.104.414.313.56.689l-1.417.236A2 2 0 0 0 1 237.009v47.611a2 2 0 0 0 1.671 1.973l1.347.225c-.135.294-.302.493-.49.607v377.681c0 41.213 22 63.208 63.223 63.208h95.074c.947-.504 2.717-.843 4.745-.843l.141.001h.194l.086-.001 33.704.005c1.849.043 3.442.37 4.323.838h95.074c41.222 0 63.223-21.999 63.223-63.212v-394.63c-.259-.275-.48-.796-.63-1.47l-.011-.133 1.655-.276A2 2 0 0 0 366 266.62v-77.611a2 2 0 0 0-1.671-1.973l-1.712-.285c.148-.839.396-1.491.698-1.811V64.213Z"
// 					></path>
// 				<path
// 					className="hero-image-foreground"
// 					d="M16 59c0-23.748 19.252-43 43-43h246c23.748 0 43 19.252 43 43v615c0 23.196-18.804 42-42 42H58c-23.196 0-42-18.804-42-42V59Z"
// 					></path>
// 				<foreignObject
// 					width="316"
// 					height="684"
// 					transform="translate(24 24)"
// 					clip-path="url(#2ade4387-9c63-4fc4-b754-10e687a0d332)"
// 					>
// 					<img
// 					alt="iMessage usage screenshot"
// 					loading="lazy"
// 					width="818"
// 					height="1672"
// 					decoding="async"
// 					data-nimg="1"
// 					style={{
// 					color: 'transparent',
// 					backgroundSize: 'cover',
// 					backgroundPosition: '50% 50%',
// 					backgroundRepeat: 'no-repeat',
// 					backgroundImage: "url('data:image/svg+xml;charset=utf-8,%3Csvg xmlns=&#x27;http%3A//www.w3.org/2000/svg&#x27; viewBox=&#x27;0 0 4 8&#x27;%3E%3Cfilter id=&#x27;b&#x27; color-interpolation-filters=&#x27;sRGB&#x27;%3E%3CfeGaussianBlur stdDeviation=&#x27;1&#x27;/%3E%3C/filter%3E%3Cimage preserveAspectRatio=&#x27;none&#x27; filter=&#x27;url(%23b)&#x27; x=&#x27;0&#x27; y=&#x27;0&#x27; height=&#x27;100%25&#x27; width=&#x27;100%25&#x27; href=&#x27;data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAICAMAAADp7a43AAAAMFBMVEXY2Nro7u/o5eny9PT+/v7Kysyu3rvS99vZ2dv57/nd3t+22cCj6bXb7t/X0Ne088SuAXuUAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAKklEQVQImRXESRIAIAgEsR5Acff/v7XMIUjaePdEdV1s1oRjAWX8CGjmDw2rAKBlSWeJAAAAAElFTkSuQmCC&#x27;/%3E%3C/svg%3E')",
// 					}}
// 					src="/assets/screenshot.png"
// 					/>
// 				</foreignObject>
// 			</svg>
// 			</div>
// 	)
// }

const HeroComponent = () => {
  const classes = useStyles();

  return (
    <div className={classes.hero}>
      <Container>
        <Grid container spacing={4}>
			<Grid item xs={12} md={8}>
			<Typography variant="h2" className={classes.heroTitle}>
				Your personal AI Dog Care Expert, just a text away.
			</Typography>
			<Typography>
				&nbsp;
			</Typography>
			<Typography variant="h6" className={classes.heroTitle}>
				Ensure your furry friend receives the best care and guidance with our AI expert dog care companion, available 24/7 through text. 
			</Typography>
			{/* <Typography>
				&nbsp;
			</Typography> */}
			{/* <GetAccessForm /> */}
			<SignUp />
			<Typography>
				&nbsp; 
			</Typography>
			{/* <Typography variant="h9" className={classes.heroTitle}>
				First {FREE_TRIAL_AMOUNT} Messages Free, $7.99 per month after!
			</Typography> */}
			{/* <DynamicUsers /> */}
		</Grid>
		<Grid item xs={12} md={4} className={classes.heroImageContainer}>
			<img
				src="./assets/iphone_pic.png" // Replace with your iPhone image URL
				alt="iPhone"
				className={classes.heroImage}
			/>
		</Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default HeroComponent;
