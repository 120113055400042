import React from 'react';
import { AppBar, Toolbar, Typography, Link, Box } from '@mui/material';

const useStyles = {
  appBar: {
    backgroundColor: '#000',
  },
  toolbar: {
    justifyContent: 'space-between',
  },
  logo: {
    fontWeight: 'bold',
    textDecoration: 'none',
    color: '#fff',
  },
  navItem: {
    marginLeft: '25px',
    textDecoration: 'none',
    fontSize: '1.1em',
    color: '#fff',
    '&:hover': {
      color: '#999',
      textDecoration: 'none',
    },
  },
};

const Header = () => {
  return (
    <AppBar position="static" sx={useStyles.appBar}>
      <Toolbar sx={useStyles.toolbar}>
        <Link href="/" variant="h6" sx={useStyles.logo}>
          DoggyDoula
        </Link>
        <Box>
          {/* <Link href="/features" sx={useStyles.navItem}>
            Features
          </Link> */}
          <Link href="mailto:andre@miscellaneous.ink" sx={useStyles.navItem}>
            Contact
          </Link>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
