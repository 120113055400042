import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Typography, List, ListItem, ListItemText, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	card: {
	  backgroundColor: theme.palette.common.black,
	  color: theme.palette.common.white,
	  margin: '2rem',
	  color: '#fff',
	},
	title: {
	  fontSize: '2rem',
	  fontWeight: 'bold',
	  marginBottom: '1rem',
	},
	price: {
	  fontSize: '1.5rem',
	  fontWeight: 'bold',
	  marginBottom: '1rem',
	},
	listItem: {
	  padding: 0,
	},
	button: {
	  backgroundColor: '#fff',
	  borderRadius: '0.375rem',
	  color: '#000',
	  fontSize: '1.25rem',
	  fontWeight: '500',
	  marginTop: '1rem',
	  padding: '0.75rem 1.5rem',
	  '&:hover': {
		backgroundColor: '#ccc',
	  },
	},
  }));
  

const Pricing = () => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography className={classes.title} variant="h4">
          Pricing
        </Typography>
        <Typography className={classes.price} variant="h5">
          Free Tier: 5 messages
        </Typography>
        <Typography className={classes.price} variant="h5">
          $7.99 per month
        </Typography>
        <List>
          <ListItem className={classes.listItem}>
            <ListItemText primary="Sending unlimited messages" />
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemText primary="No data required, only standard SMS" />
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemText primary="Anytime Cancellation" />
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemText primary="Expert Dog Care from thousands of dog care books" />
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemText primary="Immediate responses for all your dog care needs" />
          </ListItem>
        </List>
        <Button className={classes.button} variant="contained">
          Subscribe Now
        </Button>
      </CardContent>
    </Card>
  );
};

export default Pricing;
